<template >
    <div>
        <div class="filter-deals">
            <div @click="handleChange(payment_type, index)" v-for="(payment_type, index) in inventoryItems" :key="'cat_'+index">
                <el-card 
                    shadow="hover"
                    :body-style="{ width: '220px', 'min-width': '200px', cursor: 'pointer', 'background-color': (activeTab == index ? '#00b2ff' : '#d0d0d0') }" 
                >
                    <span class="font-bold text-center w-100">
                        <span class="d-block filter_categ_name">
                            {{ payment_type.name }}
                        </span>
                        <span class="d-block deal_counts deal_total"> 
                            <i class="fas fa-money-check-alt"></i> {{ payment_type.total | formatNumber }} {{ $currency }}
                        </span>
                        <span class="d-block deal_counts deal_paid"> 
                            <i class="far fa-credit-card"></i> {{ payment_type.paid | formatNumber }} {{ $currency }}
                        </span>
                    </span>
                    
                    
                </el-card>
            </div>
            
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    
    data() {
      return {
        inventoryItems: [],
        activeTab: -1,
      };
    },
    created() {
        this.updateInventory({no_parent: true, with_count: true})
            .then(res => {
                this.inventoryItems = JSON.parse(JSON.stringify(this.inventory));
            })
            .catch(err => {
                console.log(err, 'errerr');
            });
        
    },
    computed: {
        ...mapGetters({
            inventory: "dealPayment/paymentTypeTotals",
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "dealPayment/totalsByPaymentType",
        }),
        handleChange(category, index) {
            this.activeTab = index;
            this.$emit('updateActiveFilter', category.id);
        }
    },
  };
</script>
<style>
    .filter-deals {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #fff;
    }
    .filter_categ_name {
        font-size: 16px;
    }
    .deal_counts {
        padding-top: 10px;
        font-size: 14px;
    }
    .deal_total {
        color: #0064fa;
    }
    .deal_paid {
        color: #2a8d02;
    }
</style>