<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.dealPayments") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <filter-table @updateActiveFilter="updateActiveFilter" class="mb-4" ref="filter_table"/>

                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th class="w50p" v-if="columns.deal_id.show">
                                {{ columns.deal_id.title }}
                            </th>

                            <th v-if="columns.payment_type_id.show">
                                {{ columns.payment_type_id.title }}
                            </th>

                            <th v-if="columns.price.show">
                                {{ columns.price.title }}
                            </th>

                            <th v-if="columns.currency_rate.show">
                                {{ columns.currency_rate.title }}
                            </th>

                            <th v-if="columns.currency_id.show">
                                {{ columns.currency_id.title }}
                            </th>

                            <th v-if="columns.paid.show">
                                {{ columns.paid.title }}
                            </th>

                            <th v-if="columns.comment.show">
                                {{ columns.comment.title }}
                            </th>

                            <th v-if="columns.payment_date.show">
                                {{ columns.payment_date.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.deal_id.show">
                                <crm-input
                                    :placeholder="columns.deal_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal_id"
                                    class="id_input"
                                ></crm-input>
                            </th>
                            <th v-if="columns.payment_type_id.show"></th>
                            <th v-if="columns.price.show"></th>
                            <th v-if="columns.currency_rate.show"></th>
                            <th v-if="columns.currency_id.show"></th>
                            <th v-if="columns.paid.show"></th>
                            <th v-if="columns.comment.show"></th>

                            <th v-if="columns.payment_date.show">
                                <crm-date-picker
                                    :placeholder="columns.payment_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.payment_date"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="deal_payment in list"
                            :key="deal_payment.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ deal_payment.id }}
                            </td>

                            <td v-if="columns.deal_id.show">
                                {{ deal_payment.deal_id }}
                            </td>

                            <td v-if="columns.payment_type_id.show">
                                {{ deal_payment.paymentType ? deal_payment.paymentType.name : ''}}
                            </td>

                            <td v-if="columns.price.show">
                                {{ deal_payment.price }}
                            </td>

                            <td v-if="columns.currency_rate.show">
                                {{ deal_payment.currency_rate }}
                            </td>

                            <td v-if="columns.currency_id.show">
                                {{ deal_payment.currency ? deal_payment.currency.code : '' }}
                            </td>

                            <td v-if="columns.paid.show">
                                <span v-if="deal_payment.paid" class="mm_paid">
                                    {{ $t('message.paid') }}
                                </span>
                                <span v-else class="mm_not_paid">
                                    {{ $t('message.not_paid') }}
                                </span>
                            </td>

                            <td v-if="columns.comment.show">
                                {{ deal_payment.comment }}
                            </td>

                            <td v-if="columns.payment_date.show">
                                {{ deal_payment.payment_date }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ deal_payment.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ deal_payment.updated_at }}
                            </td>

                            <td v-if="columns.settings.show" class="settings-td" >
                                <el-button v-if="!deal_payment.paid" @click="setToPaid(deal_payment)" size="mini" type="warning" icon="el-icon-check"></el-button>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>
        </div>
        <el-dialog
            :title="$t('message.payment')"
            :visible.sync="dialogPayment"
            width="30%">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="top">
                <el-form-item prop="payment_date" :label="$t('message.payment_date')">
                    <el-date-picker
                        v-model="form.payment_date"
                        class="w-100"
                        type="datetime"
                        :placeholder="$t('message.payment_date')"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="comment" :label="$t('message.comment')">
                    <el-input
                        type="textarea"
                        autosize
                        :autosize="{ minRows: 2 }"
                        :placeholder="$t('message.comment')"
                        v-model="form.comment">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogPayment = false">Cancel</el-button>
                <el-button v-loading="loadingButton" type="primary" @click="save()">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import filterTable from "./components/filterTable";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "dealPayment",
    mixins: [list],
    components: {
        filterTable
    },

    data() {
        return {
            dialogPayment: false,
            loadingButton: false,
            form: {
                id: '',
                payment_date: '',
                paid: true,
                comment: '',
            },
            rules: {
                payment_date: [
                    { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.payment_date')}), trigger: 'change' },
                ],
            }
        };
    },
    
    computed: {
        ...mapGetters({
            list: "dealPayment/list",
            columns: "dealPayment/columns",
            pagination: "dealPayment/pagination",
            filter: "dealPayment/filter",
            sort: "dealPayment/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            update: "dealPayment/update",
            updateList: "dealPayment/index",
            setPagination: "dealPayment/setPagination",
            updateSort: "dealPayment/updateSort",
            updateFilter: "dealPayment/updateFilter",
            updateColumn: "dealPayment/updateColumn",
            updatePagination: "dealPayment/updatePagination",
            show: "dealPayment/show",
            empty: "dealPayment/empty",
            delete: "dealPayment/destroy",
            refreshData: "dealPayment/refreshData",
        }),
        updateActiveFilter(payment_type_id){
            this.$set(this.filterForm, 'payment_type_id', payment_type_id)
        },
        setToPaid(deal_payment) {
            this.form.id = deal_payment.id;
            this.form.payment_date = this.formatDate();
            this.form.paid = true;
            this.form.comment = '';

            this.dialogPayment = true;
        },
        formatDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        },
        save() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then(res => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.fetchData();
                            this.dialogPayment = false;
                        })
                        .catch(err => {
                            this.loadingButton = false;
                            this.dialogPayment = false;
                        });

                }
            });
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('dealPayment/EMPTY_LIST');
        next()
    },
};
</script>

<style>
.mm_paid {
    color: white;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(44, 211, 72);
}

.mm_not_paid {
    color: white;
    padding: 4px;
    border-radius: 4px;
    background-color: rgb(247, 82, 82);
}

</style>